import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";

import customerDetailsService from "@app/services/customers/customerDetailsService";

import Breadcrumb from "@components/Breadcrumb";
import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

import AdminContainer from "@layout/AdminContainer";

import SelectedSubscriptionDetails from "@pages/shared/subscription/SelectedSubscriptionDetails";

import { ROUTES } from "@utils/constant";
import Tabs from "@components/Tabs";
import SubscriptionOrders from "./SubscriptionOrders";

export const SubscriptionDetails = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const { showErrorToast } = useToast();

  const { id, subscription_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [customerDetail, setCustomerDetail] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsLoading(true);

        const { data: { data: customerData = {} } = {} } =
          await customerDetailsService(id);

        setCustomerDetail(customerData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [id, messages.exception_error_message, showErrorToast, subscription_id]);

  const headerConfig = {
    title: `${messages.subscription} #${subscription_id}`,
  };

  const breadCrumbItems = [
    {
      label: messages.subscriptions_title,
      url: ROUTES.SUBSCRIPTION_LIST.URL,
    },
    {
      label: `#${subscription_id}`,
    },
  ];

  const renderSubscriptionDetails = useCallback(
    () =>
      isLoading ? (
        <ProgressSpinner />
      ) : (
        <SelectedSubscriptionDetails customerDetails={customerDetail} />
      ),
    [customerDetail, isLoading]
  );

  const TAB_CONTENT = useMemo(
    () => [
      {
        title: messages.subscription,
        content: renderSubscriptionDetails(),
        url: `/admin/subscription/${subscription_id}/kunder/${id}`,
      },
      {
        title: messages.title_orders,
        content: <SubscriptionOrders />,
        url: `/admin/subscription/${subscription_id}/kunder/${id}/bestallningar`,
      },
    ],
    [
      id,
      messages.subscription,
      messages.title_orders,
      renderSubscriptionDetails,
      subscription_id,
    ]
  );

  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    const activeTabIndex = TAB_CONTENT.findIndex(item => item.url === pathname);
    setActiveIndex(activeTabIndex);
  }, [TAB_CONTENT, history]);

  const handleTabChange = event => {
    const { index } = event;
    history.push({
      pathname: TAB_CONTENT[index]?.url,
    });
  };

  return (
    <AdminContainer config={headerConfig}>
      <Breadcrumb items={breadCrumbItems} p={0} pb={[1, 1, 1, 10]} />
      <Tabs
        content={TAB_CONTENT}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
    </AdminContainer>
  );
};
export default SubscriptionDetails;
