import React from "react";
import PropTypes from "prop-types";

import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/i18n/swedish";

import surveyTheme from "@assets/survey_theme.json";

import Config from "@src/config";

const SurveyForm = ({
  data,
  formConfig,
  mode = "edit",
  onComplete,
  onRefLoaded,
}) => {
  const survey = new Model(formConfig);
  survey.applyTheme(surveyTheme);
  survey.onComplete.add(onComplete);
  survey.locale = Config.language;
  survey.showCompleteButton = false;
  survey.data = { ...survey.data, ...data };
  survey.mode = mode;

  onRefLoaded?.(survey);

  return <Survey model={survey} />;
};

SurveyForm.propTypes = {
  data: PropTypes.object,
  formConfig: PropTypes.object,
  mode: PropTypes.string,
  onComplete: PropTypes.func,
  onRefLoaded: PropTypes.func,
};

export default SurveyForm;
