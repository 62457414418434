import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import Div from "@components/Div";

import { KLARNA_CHECKOUT } from "@utils/constant";
import { renderSnippet } from "@utils/utils";

import PaymentOption from "./PaymentOption";

const TotalOrderSummary = ({
  paymentMethod = "",
  isInvoice = false,
  isCreditOnly = false,
  isConsent = false,
  promoCode = "",
  billingAccountId = null,
}) => {
  const [html, setHTML] = useState("");

  const htmlSnippet = useCallback((val) => setHTML(val.html_snippet), []);

  return (
    <Div mt={4}>
      <PaymentOption
        htmlSnippet={htmlSnippet}
        paymentMethod={paymentMethod}
        isInvoice={isInvoice}
        isConsent={isConsent}
        promoCode={promoCode}
        billingAccountId={billingAccountId}
      />

      <Div mt={5} id="my-checkout-container">
        {!isCreditOnly &&
          paymentMethod === KLARNA_CHECKOUT &&
          !!html?.length &&
          renderSnippet(html)}
      </Div>
    </Div>
  );
};

TotalOrderSummary.propTypes = {
  paymentMethod: PropTypes.string,
  isInvoice: PropTypes.bool,
  isCreditOnly: PropTypes.bool,
  isConsent: PropTypes.bool,
  promoCode: PropTypes.string,
  billingAccountId: PropTypes.string,
};

export default TotalOrderSummary;
