import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";

import useAuthorization from "@hooks/useAuthorization";

import { AdminPermissions } from "@src/enum/Permissions";

import { FORM_INPUT_TYPE, SERVICE_TYPE } from "@utils/enum";
import { enumValueToTranslationKey, getFormattedNumber } from "@utils/utils";
import { COURT_PACKAGE_OPTIONS, INPUT } from "@utils/constant";


const getConfig = data => {
  const courtPackageOptions = [
    {
      label: (
        <FormattedMessage
          id={`label_court_package_${COURT_PACKAGE_OPTIONS.SMALL}`}
        />
      ),
      value: COURT_PACKAGE_OPTIONS.SMALL,
    },
    {
      label: (
        <FormattedMessage
          id={`label_court_package_${COURT_PACKAGE_OPTIONS.MEDIUM}`}
        />
      ),
      value: COURT_PACKAGE_OPTIONS.MEDIUM,
    },
    {
      label: (
        <FormattedMessage
          id={`label_court_package_${COURT_PACKAGE_OPTIONS.LARGE}`}
        />
      ),
      value: COURT_PACKAGE_OPTIONS.LARGE,
    },
  ];

  const configuration = {
    details: [
      {
        key: "id",
        translationKey: "title_id",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.id,
      },
      {
        key: "created_at",
        translationKey: "created_at",
        type: FORM_INPUT_TYPE.CALENDAR,
        editable: false,
        value: data.created_at,
      },
      {
        key: "name",
        translationKey: "label_service_name",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.name,
        fullWidth: true,
      },
      {
        key: "state",
        translationKey: "label_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="active" />,
            value: "active",
          },
          {
            label: <FormattedMessage id="inactive" />,
            value: "inactive",
          },
        ],
        selectorField: "label",
        value: data.state,
        fullWidth: true,
      },
      {
        key: "price",
        translationKey: "title_pris",
        type: FORM_INPUT_TYPE.TEXT,
        value: `${getFormattedNumber(data.price)} kr`,
        fullWidth: true,
      },
      {
        key: "delivery_time",
        translationKey: "delivery_time_label",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.delivery_time,
      },
      {
        key: "listing_order",
        translationKey: "label_listing_order",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.listing_order,
      },
      {
        key: "sku",
        translationKey: "label_sku",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.sku,
        fullWidth: true,
      },
      {
        key: "default_selected",
        translationKey: "label_default_selected",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.default_selected,
      },
      {
        key: "default_available",
        translationKey: "label_default_availability",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.default_available,
      },
      {
        key: "purchase_by_credits",
        translationKey: "label_credits_purchase_available",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.purchase_by_credits,
      },
      {
        key: "price_credits",
        translationKey: "label_price_in_credits",
        type: FORM_INPUT_TYPE.TEXT,
        textType: INPUT.TYPE.NUMBER,
        value: data.price_credits,
      },
    ],
    description: [
      {
        key: "description",
        translationKey: "",
        type: FORM_INPUT_TYPE.EDITOR,
        value: data.description,
        fullWidth: true,
      },
    ],
    configuration: [
      {
        key: "type",
        translationKey: "label_product_type",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.type,
      },
      {
        key: "service_type",
        translationKey: "label_service_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="analysis" />,
            value: SERVICE_TYPE.ANALYSIS,
          },
          {
            label: <FormattedMessage id="assignment" />,
            value: SERVICE_TYPE.ASSIGNMENT,
          },
          {
            label: <FormattedMessage id="servicetype_bundle" />,
            value: SERVICE_TYPE.BUNDLE,
          },
        ],
        selectorField: "label",
        value: data.service_type,
        fullWidth: true,
      },
      {
        key: "analysis_type",
        translationKey: "label_analysis_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="general_analysis" />,
            value: "general-analysis",
          },
          {
            label: <FormattedMessage id="role_specific_analysis" />,
            value: "role-specific-analysis",
          },
          {
            label: <FormattedMessage id="general_analysis_actapublica" />,
            value: "general-analysis-actapublica",
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: "manual",
          },
        ],
        selectorField: "label",
        value: data.analysis_type,
        fullWidth: true,
      },
      {
        key: "process_type",
        translationKey: "label_process_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="label_automatic" />,
            value: "automatic",
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: "manual",
          },
        ],
        selectorField: "label",
        value: data.process_type || data.analysis_type,
        fullWidth: true,
      },
      {
        key: "data_source",
        translationKey: "label_data_source",
        type: FORM_INPUT_TYPE.MULTISELECT,
        options: data?.data_source_types || [],
        selectorField: "name",
        selectAllLabel: <FormattedMessage id="label_all_sources" />,
        value: data.data_source,
        fullWidth: true,
      },
      {
        key: "court_package",
        type: FORM_INPUT_TYPE.DROPDOWN,
        translationKey: "court_package",
        options: courtPackageOptions,
        isHidden: value => {
          return (
            value &&
            Array.isArray(value.data_source) &&
            value.data_source.some(item => item.code.toLowerCase() === "court")
          );
        },
        value: data.court_package,
      },
      {
        key: "fortnox_article_number",
        translationKey: "label_fortnox_article_number",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.fortnox_article_number,
        fullWidth: true,
      },
      {
        key: "is_case",
        translationKey: "label_case",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.is_case,
      },
      {
        key: "is_report",
        translationKey: "label_report",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.is_report,
      },
    ],
  };
  return configuration;
};

const DetailsTab = ({ data, dataSourceTypes, onUpdateService }) => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();

  const hasProductsPermission = hasAllPermissions([
    AdminPermissions.AdminWriteProducts,
  ]);

  const formattedData = {
    ...data,
    type: messages[`label_${enumValueToTranslationKey(data?.type)}`],
    data_source_types: dataSourceTypes,
    is_case: !!data.is_case,
    is_report: !!data.is_report,
  };

  const { details, description, configuration } = getConfig(formattedData);

  const handleUpdateService = values => {
    onUpdateService(values);
  };

  const handleUpdateConfiguration = ({ is_case, is_report, ...values }) => {
    const payload = {
      ...values,
      is_case: Number(is_case),
      is_report: Number(is_report),
    };

    handleUpdateService(payload);
  };

  return (
    <Div
      width={[1, 1, 1, 800]}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
    >
      <EditableFieldsCard
        title={messages.label_details}
        config={details}
        data={formattedData}
        onSubmit={handleUpdateService}
        isEditPermission={hasProductsPermission}
      />

      <EditableFieldsCard
        title={messages.title_product_description}
        config={description}
        data={formattedData}
        onSubmit={handleUpdateService}
        isEditPermission={hasProductsPermission}
      />

      <EditableFieldsCard
        title={messages.title_configuration}
        config={configuration}
        data={formattedData}
        onSubmit={handleUpdateConfiguration}
        isEditPermission={hasProductsPermission}
      />
    </Div>
  );
};

DetailsTab.propTypes = {
  data: PropTypes.object,
  dataSourceTypes: PropTypes.array,
  onUpdateService: PropTypes.func,
};

export default DetailsTab;
