import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import Checkbox from "@components/Checkbox";
import Div from "@components/Div";
import { H3, H5 } from "@components/Heading";
import Icon, { SmallEllipseIcon, BundleIcon } from "@components/Icon";
import Link from "@components/Link";
import { Text } from "@components/Text";
import Tooltip from "@components/ToolTip";
import Currency from "@components/Currency";
import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions } from "@src/enum/Permissions";
import { SUBSCRIPTION_STATUS, BUNDLE } from "@utils/constant";
import { isCustomerUser } from "@utils/utils";

const StyledH3 = styled(H3)`
  text-transform: none;
`;

const getDisplayedServices = (
  allServices = [],
  isAllServicesVisible = false
) => {
  if (!allServices?.length) {
    return [];
  }

  if (isAllServicesVisible || allServices.length <= 6) {
    return allServices;
  }

  const collapsedServicesList = allServices.slice(0, 6);

  return collapsedServicesList;
};

const ServiceList = ({
  onChange,
  checked,
  index,
  subscriptionStatus,
  handleShowAllServiceOnClick,
  formikProps,
}) => {
  const { messages } = useIntl();
  const { setValues, values } = formikProps;

  const { services = [] } = checked;

  const displayedServices = getDisplayedServices(
    services,
    checked?.showAllServices
  );
  const { hasAllPermissions } = useAuthorization();

  const hasPricePermission = hasAllPermissions([
    CustomerPermissions.CustomerReadPrices,
  ]);

  const getPriceString = service => {
    const shouldRenderPrepaidLabel =
      isCustomerUser() &&
      service?.purchase_by_credits &&
      subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE;

    if (!hasPricePermission) {
      return "";
    }

    if (shouldRenderPrepaidLabel) {
      return messages.prepaid_price_label;
    }

    const currencyString = <Currency value={service?.price} />;

    return currencyString;
  };

  return (
    <Div
      display="inline-flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
    >
      <Div display="flex" justifyContent="space-between">
        <StyledH3>{`${messages.search_label_service_available}:`}</StyledH3>
      </Div>

      <Div
        display="grid"
        gridTemplateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        gridColumnGap={5}
        gridRowGap="20px"
        mt={3}
      >
        {displayedServices.map(service => (
          <Div
            key={service.id}
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Div
              display="inline-flex"
              alignItems="center"
              wordBreak="break-word"
              flex={1}
            >
              <Checkbox
                checked={service?.isChecked}
                onChange={onChange.bind(
                  this,
                  service.id,
                  index,
                  setValues,
                  values
                )}
              />

              <Div ml={2} display={"flex"} alignItems="center">
                <H5>
                  {service.name}

                  {service.service_type === BUNDLE && (
                    <BundleIcon ml={2} name="bundle-tag"></BundleIcon>
                  )}
                  <SmallEllipseIcon
                    ml={2}
                    name="info"
                    className={`serviceName-${service.id}`}
                  >
                    <Tooltip
                      id={`${service.id}-${service.name}`}
                      key={`${service.id}-${service.name}`}
                      content={service.description}
                      target={`.serviceName-${service.id}`}
                      isHtml={true}
                    />
                  </SmallEllipseIcon>
                </H5>
              </Div>
            </Div>

            <Div
              ml={2}
              display="inline-flex"
              justifyContent="end"
              width="120px"
              textAlign="right"
            >
              <Text
                fontWeight="var(--medium-weight) !important"
                fontSize={"var(--fs-text-m) !important"}
                lineHeight={"var(--fs-text-m) !important"}
                whiteSpace={"nowrap"}
              >
                {getPriceString(service)}{" "}
              </Text>
            </Div>
          </Div>
        ))}
      </Div>

      {services.length > 6 && (
        <Div mt={3}>
          <Link
            width={1}
            label={
              checked.showAllServices
                ? messages.search_label_see_less
                : messages.search_label_see_all
            }
            onClick={handleShowAllServiceOnClick.bind(
              this,
              index,
              setValues,
              values
            )}
          >
            <Icon
              ml={1}
              name={checked.showAllServices ? "up-arrow" : "download"}
            />
          </Link>
        </Div>
      )}
    </Div>
  );
};

ServiceList.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  index: PropTypes.number,
  subscriptionStatus: PropTypes.string,
  handleShowAllServiceOnClick: PropTypes.func,
  formikProps: PropTypes.object,
};

export default ServiceList;
