import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import Breadcrumb from "@components/Breadcrumb";
import DataTable from "@components/DataTableV2/DataTable";

import {
  ADMIN_ANALYSIS_API,
  USER_ORDER_REDIRECT,
  RAPPORT_URL,
} from "@utils/constant";
import UserContainer from "@layout/UserContainer";
import GradeComponent from "@components/Report/GradeComponent";
import { StatusComponent } from "@components/Report/StatusComponent";
import fetchProductById from "@app/services/services/fetchProductById";
import customerService from "@app/services/customers/customerService";
import { ReportStatus, reportStatusArray } from "@src/enum/reportStatus";
import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions } from "@src/enum/Permissions";
import { useToast } from "@hooks/useToast";

const FILTER_KEY_FROM = "filter[created_at][gte]";
const FILTER_KEY_TO = "filter[created_at][lte]";

const List = () => {
  const { messages } = useIntl();
  const [customer, setCustomer] = useState([]);
  const [services, setServices] = useState([]);
  const { showErrorToast } = useToast();
  const { hasAnyPermissions } = useAuthorization();
  const hasreportAnalysisPermission = hasAnyPermissions([
    CustomerPermissions.CustomerReadReportAnalysis,
    CustomerPermissions.CustomerReadSelfReportsAnalysis,
  ]);

  useEffect(() => {
    const loadFilterData = async () => {
      try {
        const [customerResponse, servicesResponse] = await Promise.all([
          customerService(),
          fetchProductById(),
        ]);

        // Process customer data
        const { data: { data: customers = [] } = {} } = customerResponse;
        const formattedCustomers = customers.map(o => ({
          name: `${o?.customer_name} #${o?.id}`,
          code: o?.id,
        }));

        // Process services data
        const { data: { data: products = [] } = {} } = servicesResponse;
        const formattedServices = products.map(o => ({
          name: `${o?.name} #${o?.id}`,
          code: o?.id,
        }));

        // Set state
        setCustomer(formattedCustomers);
        setServices(formattedServices);
      } catch (error) {
        showErrorToast(messages.error);
      }
    };
    loadFilterData();
  }, [messages.error, showErrorToast]);

  const dataTableConfig = {
    header: {
      title: "menu_reports",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.title_customers,
        id: "filter[customer_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[customer_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[customer_id]",
            options: customer,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_services,
        id: "filter[orderItem.product_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[orderItem.product_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[orderItem.product_id]",
            options: services,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.grades,
        id: "filter[grades]",
        type: "multiselect",
        options: [
          {
            name: "filter[grades]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[grades]",
            options: [
              {
                name: "+3",
                code: "+3",
              },
              {
                name: "+2",
                code: "+2",
              },
              {
                name: "+1",
                code: "+1",
              },
              {
                name: "0",
                code: "0",
              },
              {
                name: "-1",
                code: "-1",
              },
              {
                name: "-2",
                code: "-2",
              },
              {
                name: "-3",
                code: "-3",
              },
            ],
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "multiselect",
        options: [
          {
            name: "filter[status]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[status]",
            options: reportStatusArray.map((singleStatus, index) => {
              return {
                name: messages[ReportStatus[singleStatus]],
                code: singleStatus,
                key: index,
              };
            }),
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_customer_reports_no_objects_found",
    enable_search: false,
    enable_query_builder: true,
    api: {
      resource: `/${ADMIN_ANALYSIS_API}`,
      method: "GET",

      params: [
        [
          "fields",
          [
            "id",
            "created_at",
            "status",
            "order_id",
            "grades",
            "person_initials",
            "order_item_id",
            "product_id",
            "analysis_type",
            "grade_data",
            "is_case",
          ],
        ],
        ["include", ["orderItem", "product"]],
      ],
    },
    columns: [
      {
        title: "title_order_id",
        db_field: "order_id",
        type: "id",
        width: "100px",
        className: "text-blue",
        href: value => {
          const { order_id } = value;
          return `${USER_ORDER_REDIRECT}?id=${order_id}`;
        },
        formatter: value => `#${value}`,
        sortable: true,
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
        width: "14%",
      },
      {
        title: "title_person",
        db_field: "person_initials",
        width: "100px",
        type: "text",
        sortable: true,
      },
      {
        title: "grade",
        db_field: "grades",
        type: "link",
        width: "100px",
        formatter: (value, report) => {
          return <GradeComponent value={value} report={report} />;
        },
      },

      {
        title: "label_service",
        db_field: "product.name",
        type: "text",
        className: "text-black",
        sortable: true,
      },
      {
        title: "label_status",
        db_field: "status",
        type: "text",
        formatter: (value, db_fields) => {
          return <StatusComponent value={value} db_fields={db_fields} />;
        },
        className: "text-grey-dark",
        sortable: true,
        width: "13%",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            showWhen: ({ created_at, status }) => {
              const date = new Date();
              date.setDate(date.getDate() - 30);
              const _created_at = new Date(created_at);
              const isExpired = date > _created_at;
              return (
                status === "completed" &&
                !isExpired &&
                hasreportAnalysisPermission
              );
            },
            href: ({ id, status }) => {
              if (status === "completed") {
                return `../${RAPPORT_URL}?id=${id}`;
              }
              return `rapporter`;
            },
            label: messages.label_new_customer,
          },
        ],
      },
    ],
  };

  let headerConfig = {
    title: messages.menu_reports,
  };

  const breadCrumbItems = [
    {
      label: messages.my_profile,
    },
    { label: messages.title_reports },
  ];

  return (
    <UserContainer config={headerConfig} wide>
      <Breadcrumb p={0} items={breadCrumbItems} />

      {customer.length > 0 && services.length > 0 && (
        <DataTable config={dataTableConfig} />
      )}
    </UserContainer>
  );
};

export default List;
