import { fetchAllProducts } from "@app/services/services/fetchProductById";
import getSubscription from "@app/services/subscription/getSubscription";
import {
  ROUTES,
  PRODUCT_TYPE_SUBSCRIPTION,
  INDIVIDUAL,
  COMPANY,
  SUBSCRIPTION_STATUS,
  DAYS,
  OBJECT,
} from "@utils/constant";
import personInformationSearchService from "@app/services/search/personInformationSearchService";
import moment from "moment/moment";

export const loadServicesData = async (customer_id = "") => {
  const type = PRODUCT_TYPE_SUBSCRIPTION;
  const servicesResponse = await fetchAllProducts({ type, customer_id });
  return servicesResponse.data.data || "";
};

export const loadActiveSubscriptionData = async (userInfo, params = "") => {
  const {
    customer: { id: customerId },
  } = userInfo;
  const servicesResponse = await getSubscription(customerId, params);
  const { data: { data = [] } = {} } = servicesResponse;
  const subscription = data.find((o) => o.customer_id == customerId);
  return subscription;
};

export const redirectOnSubmit = (id, history) => {
  history.push(ROUTES.SUBSCRIPTION_CHECKOUT.URL + "?id=" + id);
};

export const checkUserPermission = (history, userInfo = {}) => {
  const { customer: { type = "" } = {} } = userInfo;
  if (
    type == INDIVIDUAL ||
    (type == COMPANY &&
      userInfo.permissions.subscription != SUBSCRIPTION_STATUS.READ &&
      userInfo.permissions.subscription != SUBSCRIPTION_STATUS.WRITE)
  ) {
    history.push(ROUTES.COMPANY_INFO.URL);
  }
};

export const calculateSingleAnalysisPrice = (price, total_credtis) => {
  let price_per_analysis = price / total_credtis;
  return Number(price_per_analysis.toFixed());
};

export const checkSubscriptionPermissions = (
  subscription,
  userInfo,
  message,
  messages,
  history,
) => {
  if (userInfo?.customer?.type == INDIVIDUAL) {
    message = messages.subscription_access_denied_for_individual;
  } else if (
    userInfo?.customer?.type == COMPANY &&
    userInfo.permissions.subscription != SUBSCRIPTION_STATUS.WRITE
  ) {
    message = messages.permissions_access_denied_for_subscription;
  } else if (
    userInfo?.customer?.type == COMPANY &&
    subscription &&
    subscription.status == SUBSCRIPTION_STATUS.STOPPED
  ) {
    message = messages.subscription_stopped;
  } else if (
    userInfo?.customer?.type == COMPANY &&
    subscription &&
    subscription.status == SUBSCRIPTION_STATUS.ACTIVE &&
    history.location.state === undefined
  ) {
    // it would be redirect on upgrade subscription page but for we don't have url of upgrade subscription page
    history.push(ROUTES.UPGRADE_SUBSCRIPTION.URL);
  }
  return message;
};

export const loadWatchListObjects = async (
  ssnNumbers = [],
  customerId = null,
) => {
  return personInformationSearchService(ssnNumbers, customerId, true).then(
    ({ data = [] }) => data,
  );
};

export const checkIfObject = (ssnList) => {
  return typeof ssnList === OBJECT ? Object.values(ssnList) : ssnList;
};

export const calculateDateExpiryDays = (date, expiryDays) => {
  const createdDate = moment(date);
  const futureDate = createdDate.add(expiryDays, DAYS);
  const remainingDays = futureDate.diff(moment(), DAYS);
  return remainingDays;
};

export const handleException = (
  setShowErrorModal,
  setErrorMessage,
  errorMessage,
  error = {},
) => {
  if (error.response.status >= 400 && error.response.status < 600) {
    if (error.response.status === 422) {
      setShowErrorModal(true);
      setErrorMessage(error.response.data.message);
      return;
    }
    setShowErrorModal(true);
    setErrorMessage(errorMessage);
  }
};
