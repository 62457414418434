import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Form, Field, useFormikContext } from "formik";

import Autocomplete from "@components/JsonForm/Autocomplete";
import Calendar from "@components/JsonForm/Calendar";
import Div from "@components/Div";
import Dropdown from "@components/JsonForm/Dropdown";
import Multiselect from "@components/JsonForm/Multiselect";
import { TextMediumWeight } from "@components/Text";
import Textarea from "@components/JsonForm/Textarea";
import EditorComponent from "@components/JsonForm/EditorComponent";
import TextInput from "@components/JsonForm/TextInput";
import TextPassword from "@components/JsonForm/TextPassword";
import FileUploadField from "@components/JsonForm/FileUploadField";
import MultiFileUpload from "./MultiFileUpload";

const FieldType = {
  text: TextInput,
  password: TextPassword,
  dropdown: Dropdown,
  multiselect: Multiselect,
  textarea: Textarea,
  calendar: Calendar,
  autocomplete: Autocomplete,
  fileupload: FileUploadField,
  editor:EditorComponent,
  multifiles: MultiFileUpload,
};

const validate = async (validation, value) => {
  try {
    await validation?.validate(value);
  } catch (error) {
    return error.message;
  }
};

const JsonForm = ({ config, formLayout }) => {
  const { messages } = useIntl();

  const formik = useFormikContext();
  const { values } = formik;
  const filteredConfig = config.filter(({ show = true }) =>
    typeof show === "function" ? show(values || {}) : show,
);
  return (
    <Div width="100%">
      <Form>
        <Div {...formLayout}>
          {filteredConfig.map(
            ({
              name,
              label,
              fieldType,
              placeholder,
              options,
              validation,
              optionField,
              completeMethod,
              renderItem,
              maxLength,
            }) => {
              const Component = FieldType[fieldType];

              return (
                Component && (
                  <Div key={name} mb={3} mt={3} pt={1} mr={3} flex="1 1 40%">
                    <TextMediumWeight>
                      {label} {validation && <span>*</span>}
                    </TextMediumWeight>
                    <Field
                      name={name}
                      as={Component}
                      label={messages[label]}
                      placeholder={messages[placeholder]}
                      options={options}
                      validate={validate.bind(this, validation)}
                      optionField={optionField}
                      completeMethod={completeMethod}
                      renderItem={renderItem}
                      maxLength={maxLength}
                    />
                  </Div>
                )
              );
            },
          )}
        </Div>
      </Form>
    </Div>
  );
};

JsonForm.propTypes = {
  title: PropTypes.string,
  config: PropTypes.array,
  validation: PropTypes.object,
  formLayout: PropTypes.object,
};

export default JsonForm;
