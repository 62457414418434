import React from "react";
import { FormattedMessage } from "react-intl";

const DeliveryMethodOptions = [
  {
    label: <FormattedMessage id="admin_customer_details_delivery_method_email_with_encrypted_url" />,
    value: "email_with_encrypted_url",
  },
  {
    label: <FormattedMessage id="admin_customer_details_delivery_method_recommended_mail" />,
    value: "recommended_mail",
  },
  {
    label: <FormattedMessage id="other" />,
    value: "other",
  },
];

export default DeliveryMethodOptions;